@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .modal {
    font-family: var(--font-coinbase-mono) !important;
    background: #1e2025;
    border: 1px solid rgba(rgba(255, 255, 255, 0.3));
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
      0px 3px 5px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(62px);
  }
}

.statusDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.statusDot-Complete {
  background-color: green;
}

.statusDot-Pending {
  background-color: yellow;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
:not(.scrollbar)::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
*:not(.scrollbar) {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: auto; /* Firefox */
}

.scrollbar::-webkit-scrollbar {
  display: auto;
}

/* https://stackoverflow.com/questions/71296535/how-to-remove-arrow-on-input-type-number-with-tailwind-css */
@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.no-scroll {
  overflow: hidden;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
